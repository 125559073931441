<template>
  <div class="patient page">
    <NavMenu defaultActive="/case/list">
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_1.png" alt="ico-title" />
              <span>{{$t('case.createTitle')}}</span>
            </div>
            <div class="mark">
              <span class="sig">*</span>
              <span>{{$t('case.mark')}}</span>
            </div>
          </h2>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
          >
            <div class="form-left">
              <el-form-item :label="$t('case.serial')+':'" prop="case_number"
                ><el-input v-model="ruleForm.case_number"></el-input
              ></el-form-item>
              <el-form-item :label="$t('case.information')+':'" prop="information"
                ><el-input v-model="ruleForm.information"></el-input
              ></el-form-item>
              <el-form-item :label="$t('case.select')+':'" prop="patient_id">
				<el-select class="myselect" v-model="ruleForm.patient_id" @change="change" filterable :placeholder="$t('case.select')">
					<el-option
						v-for="(item,index) in patient_list"
						:key="index"
						:label="item.patient_name"
						:value="item.id">
					</el-option>
				</el-select>
			  </el-form-item>
			  <el-form-item :label="$t('case.patient')+':'" prop="patient_number"
			    ><el-input disabled="disabled" v-model="patient_number"></el-input
			  ></el-form-item>
              <el-form-item :label="$t('case.side')+':'" prop="slide">
                <el-radio-group v-model="ruleForm.slide">
                  <el-radio class="radio" label="0">{{$t('case.left')}}</el-radio>
                  <el-radio class="radio" label="1">{{$t('case.right')}}</el-radio>
                </el-radio-group>
              </el-form-item>
			  <el-form-item :label="$t('case.upload')+':'">
				  <el-upload
					class="upload-demo"
					:action="$baseUrl+'/dabo/upload'"
					:on-preview="handlePreview"
					:on-change="handleChange"
					:on-remove="handleRemove"
					:before-remove="beforeRemove"
					multiple
					:limit="3"
					:on-exceed="handleExceed"
					:file-list="fileList">
					<el-button size="small" type="primary">{{$t('case.btnUpload')}}</el-button>
					<!-- <div slot="tip" class="el-upload__tip"></div> -->
				  </el-upload>
			  </el-form-item>
			  <el-form-item :label="$t('case.hospital')+':'" prop="hospital"
			    ><el-input v-model="ruleForm.hospital"></el-input
			  ></el-form-item>
			  <el-form-item :label="$t('case.dealer')+':'" prop="partent"
			    ><el-input v-model="ruleForm.partent"></el-input
			  ></el-form-item>
            </div>
            <div class="form-right">
              <el-form-item :label="$t('case.remark')+':'">
                <el-input
                  :autosize="{ minRows: 6 }"
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  v-model="ruleForm.note"
                ></el-input>
              </el-form-item>
            </div>
            <div class="foot">
              <input
                type="button"
                :value="$t('common.save')"
                class="btn-submit"
                @click="submitForm('ruleForm')"
              />
              <input
                type="button"
                :value="$t('common.reset')"
                class="btn-cancel"
                @click="resetForm('ruleForm')"
              />
            </div>
          </el-form>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        case_number: "",
        information: "",
        patient_id: "",
        hospital: "",
        partent: "",
        slide: "0",
        note: "",
		files:[],
		id:""
      },
	  fileList: [],
	  patient_number:"",
	  patient_list:[],
      rules: {
        information: [
          { required: true, message: "请输入诊断信息:", trigger: "blur" },
        ],
        case_number: [
          { required: true, message: "请输入病例编号", trigger: "blur" },
        ],
		patient_id: [
			{ required: true, message: "请选择病人", trigger: "blur" },
		],
        sex: [{ required: true, message: "请选择患侧", trigger: "change" }],
        note: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },
  mounted(){
	this.getPatient();
	this.ruleForm.id = this.$route.query.id;
	if(this.ruleForm.id) this.caseDetail();
  },
  methods: {
	// 获取病例详情
	caseDetail(){
		this.$api.caseDetail(this.ruleForm.id).then(res=>{
			this.ruleForm.case_number = res.data.case_number
			this.ruleForm.information = res.data.information
			this.ruleForm.patient_id = res.data.patient_id
			this.ruleForm.hospital = res.data.hospital
			this.ruleForm.partent = res.data.partent
			this.ruleForm.slide = res.data.slide
			this.ruleForm.note = res.data.note
			this.change(this.ruleForm.patient_id)
			if(res.data.files){
				var c = res.data.files.split(",");
				c.map(rso=>{
					var n = rso.split("#");
					this.fileList.push({name:n[0],url:n[1]});
				})
			}
		})
	
	},
	change(e){
		this.patient_list.map(res=>{
			if(res.id == e){
				console.log(res.patient_number)
				return this.patient_number = res.patient_number
			}
		})
	},
	// 获取病人
	getPatient(){
		this.$api.patientList({flag:1}).then(res=>{
			this.patient_list = res.data.data;
		})
	},
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
		if(this.fileList.length > 0){
			this.ruleForm.files = [];
			this.fileList.map(res=>{
				if(res.response){
					this.ruleForm.files.push(res.name+"#"+res.response.data.url)
				}else{
					this.ruleForm.files.push(res.name+"#"+res.url)
				}
			})
			this.ruleForm.files = this.ruleForm.files.join(",");
		}
        if (valid) {
			if(this.ruleForm.id){
				this.$api.updateCase(this.ruleForm).then(res=>{
					if(res.code == 1){
						setTimeout(rso=>{
							this.$router.push('/case/list')
						},1000)
						return this.$message.success(res.msg);
					}
					this.$message.error(res.msg);
				})
				
			}else{
				this.$api.createCase(this.ruleForm).then(res=>{
					
					if(res.code == 1){
						setTimeout(rso=>{
							this.$router.go(-1)
						},1000)
						return this.$message.success(res.msg);
					}
					this.$message.error(res.msg);
				})
			
			}
			
          //表单验证通过
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
	handleChange(file, fileList){
		this.fileList = fileList.slice(-3);
	},
	handleRemove(file, fileList) {	
		this.fileList = fileList.slice(-3);
	  },
	  handlePreview(file) {
		// this.fileList = fileList.slice(-3);
	  },
	  handleExceed(files, fileList) {
		  console.log('exceed')
		this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
	  },
	  beforeRemove(file, fileList) {
		  console.log('beforeremove')
		return this.$confirm(`确定移除 ${ file.name }？`);
	  }
  },
};
</script>
<style scoped>
.right-content {
  min-height: 757px;
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
.myselect{width: 100%;}
</style>
